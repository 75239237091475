// Import
import { createApp, defineAsyncComponent } from "vue";

import { mapState, mapGetters } from "vuex";
import { mixin as clickaway } from "vue3-click-away";
import store from "./store";

// Components
// import VideoBackground from 'vue-responsive-video-background-player'
// import YouTube from 'vue3-youtube'
// import store from './store'
import { Swiper, SwiperSlide } from "swiper/vue";
import SwiperCore, { Navigation, Pagination, EffectFade } from "swiper/core";
import VueClickAway from "vue3-click-away";
// import VueCookies from 'vue3-cookies';

// Mixins
// import { sitedata } from '@/js/mixins/sitedata';

// Styles
import "swiper/swiper.scss";
import "swiper/swiper-bundle.css";

// Declare
declare const window: any;

// App main
const main = async () => {
	const app = createApp({
		delimiters: ["${", "}"],
		components: {
			Search: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Search" */ "@/js/components/Search.vue"
					)
			),
			WeatherAndTraffic: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "WeatherAndTraffic" */ "@/js/components/WeatherAndTraffic.vue"
					)
			),
			Forms: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Forms" */ '@/js/components/Forms.vue'
					)
			),
			Weather: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Weather" */ "@/js/components/parts/Weather.vue"
					)
			),
			WeatherBlock: defineAsyncComponent(() =>import(/* webpackChunkName: "WeatherBlock" */ "@/js/components/parts/WeatherBlock.vue")),
			Compass: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Compass" */ "@/js/components/parts/Compass.vue"
					)
			),

			SiteMenu: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "SiteMenu" */ "@/js/components/SiteMenu.vue"
					)
			),
			Newsletter: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Newsletter" */ "@/js/components/Newsletter.vue"
					)
			),
			ContentSlider: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "ContentSlider" */ "@/js/components/ContentSlider.vue"
					)
			),
			Tabs: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Tabs" */ "@/js/components/Tabs.vue"
					)
			),
			Tab: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "Tab" */ "@/js/components/Tab.vue"
					)
			),
			PdfLibrary: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "PdfLibrary" */ "@/js/components/PdfLibrary.vue"
					)
			),
			PdfCard: defineAsyncComponent(
				() =>
					import(
						/* webpackChunkName: "PdfCard" */ "@/js/components/PdfCard.vue"
					)
			),
			VideoYoutube: defineAsyncComponent(() =>import(/* webpackChunkName: "VideoYoutube" */ "@/js/components/parts/VideoYoutube.vue")),
			VideoVimeo: defineAsyncComponent(() =>import(/* webpackChunkName: "VideoVimeo" */ "@/js/components/parts/VideoVimeo.vue")),
			OverviewFilters: defineAsyncComponent(() =>import(/* webpackChunkName: "BlockVideo" */ "@/js/components/parts/OverviewFilters.vue")),
			Spinner: defineAsyncComponent(() =>import(/* webpackChunkName: "Spinner" */ "@/js/components/parts/Spinner.vue")),
			Accordion: defineAsyncComponent(() =>import(/* webpackChunkName: "Accordion" */ "@/js/components/parts/Accordion.vue")),
			Ships: defineAsyncComponent(() =>import(/* webpackChunkName: "Ships" */ "@/js/components/Ships.vue")),
		},
		mixins: [clickaway],
		data: () => ({
			isHovered: false,
			megahover: false,
			// scroll_position: 0,
			// scroll_direction: '',
		}),
		methods: {
			// handleScroll: function (event) {
			// 	this.scroll_direction = (document.body.getBoundingClientRect()).top > this.scroll_position ? 'up' : 'down';
			// 	this.scroll_position = (document.body.getBoundingClientRect()).top;
			// 	if (this.scroll_direction === 'down') {
			// 		document.body.classList.add('scrolled');
			// 	}
			// 	else if (this.scroll_direction === 'up') {
			// 		document.body.classList.remove('scrolled')
			// 	}
			// }
			toggleLanguageSelectorState: function () {
				console.log("toggle");
				switch (this.isLanguageSelectorOpen) {
					case true:
						store.commit("app/setLanguageSelectorState", false);
						break;

					case false:
						store.commit("app/setLanguageSelectorState", true);
						break;

					default:
						store.commit("app/setLanguageSelectorState", false);
						break;
				}
			},
			closeLanguageSelector: function () {
				if (this.isLanguageSelectorOpen) {
					store.commit("app/setLanguageSelectorState", false);
				}
			},
			openMobileMenu() {
				store.commit("app/setMobilemenuState", true);
			},
			closeMobileMenu() {
				store.commit("app/setMobilemenuState", false);
			},
			closeSiteMenu() {
				store.commit("app/setIsSiteMenuOpen", false);
			},
			toggleMobileElement(e: unknown) {
				e.target.parentNode.classList.toggle("open");
			},
			mouseOver(){
				this.isHovered = true;
				this.setMegaHover();
			},
			mouseLeave(){
				this.isHovered = false;
				this.setMegaHover();
			},
			setMegaHover(){
				if(this.isHovered){
					this.megahover = true;
				}
				else{
					setTimeout(() => this.isHovered ? '' : this.megahover = false, 10);
				}
			}
		},
		created() {
			// window.addEventListener('scroll', this.handleScroll, { passive: true });
		},
		destroyed() {
			// window.removeEventListener('scroll', this.handleScroll);
		},
		beforeMount() {
			//Get sitedata
			store.commit("app/setSiteUrl", window.siteData.siteUrl);
			store.commit("app/setCsrfToken", window.siteData.csrf);
		},
		computed: {
			...mapState({
				isLanguageSelectorOpen: (state) =>
					state.app.isLanguageSelectorOpen,
				isMobileMenuOpen: (state) => state.app.isMobileMenuOpen,
			}),
		},
		// setup() {
		// 	const onSwiper = (swiper) => {
		// 		// console.log(swiper);
		// 	};
		// 	const onSlideChange = () => {
		// 		// console.log('slide change');
		// 	};
		// 	return {
		// 		onSwiper,
		// 		onSlideChange,
		// 		EffectFade,
		// 	};
		// },
	});

	SwiperCore.use([Navigation, Pagination, EffectFade]);

	app.use(store);
	app.use(VueClickAway);
	// app.use(VueCookies);

	// Component liberaries
	// app.component('video-background', VideoBackground);
	// app.component('YouTube', YouTube);
	app.component("Swiper", Swiper);
	app.component("SwiperSlide", SwiperSlide);

	//Mixin
	// app.mixin(sitedata);

	// Mount the app
	const vm = app.mount("#app");
	return vm;
};

// Execute async function
main().then((vm) => {});

// Accept HMR as per: https://webpack.js.org/api/hot-module-replacement#accept
if (module.hot) {
	module.hot.accept();
}
