const state = () => ({
    scrollPosition: 0,
    csrfToken: "",
    siteUrl: "",
    isLanguageSelectorOpen: false,
    isSiteMenuOpen: false,
});

// Getters
const getters = {
    scrollPosition: (state, getters) => {
        return state.scrollPosition;
    },
};

// Actions
const actions = {};

// Mutations
const mutations = {
    setCsrfToken(state, token) {
        state.csrfToken = token;
    },
    setSiteUrl(state, value) {
        state.siteUrl = value;
    },
    setLanguageSelectorState(state, value) {
        state.isLanguageSelectorOpen = value;
    },
    setIsSiteMenuOpen(state, value) {
        state.isSiteMenuOpen = value;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations,
};
